import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'reset.css/reset.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import server from '@/utils/request'
import vaudio from '@/utils/vaudio'
import vtouch from '@/utils/vtouch'
import formate from "@/utils/common"
import myapp from '@/utils/myapp'
import screenDialog from "@/views/my/dialog/ScreenDialog.vue";
import './registerServiceWorker'

Vue.use(ElementUI);
Vue.config.productionTip = false

Vue.prototype.$server = server
Vue.prototype.$local = server.local
Vue.prototype.$audio = vaudio
Vue.prototype.$vtouch = vtouch
Vue.prototype.$formate = formate
Vue.prototype.$myapp = myapp

import UAParser from 'ua-parser-js';
const result = new UAParser().getResult();
Vue.prototype.$isIPad = result.device.type === 'tablet' && result.device.vendor === 'Apple';
Vue.prototype.$isAndroidTablet = result.device.type === 'tablet' && result.os.name === 'Android';
Vue.prototype.$isMobile = Vue.prototype.$isIPad || Vue.prototype.$isAndroidTablet;


//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
	document.title = `${to.meta.title} | 视觉训练系统`;

	var meta = document.querySelector('meta[name="viewport"]');
	if (meta) {
		meta.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no';
	} else {
		// 如果不存在，则创建新的meta viewport标签并插入
		meta = document.createElement('meta');
		meta.name = 'viewport';
		meta.content = 'width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;';
		document.head.appendChild(meta);
	}

	const role = localStorage.getItem('__htsclient_userInfo__');
	let expireTime = localStorage.getItem('__htsclient_expireTime__');
	let now = new Date().getTime();
	let login = role && (expireTime > now);

	if (!login && to.path !== '/login' && to.path !== '/') {
		next('/');
	} else {
		// 简单的判断IE10及以下不进入富文本编辑器，该组件不兼容
		if (navigator.userAgent.indexOf('MSIE') > -1 && to.path === '/editor') {
			Vue.prototype.$alert('vue-quill-editor组件不兼容IE10及以下浏览器，请使用更高版本的浏览器查看', '浏览器不兼容通知', {
				confirmButtonText: '确定'
			});
		} else {
			next();
		}
	}
});



function preventDefault(e) {
	e.preventDefault();
}

function preventAction() {
	// 禁用鼠标右键点击
	document.addEventListener('contextmenu', function(event) {
		event.preventDefault();
	});

	// 阻止文本选择
	document.body.style.webkitUserSelect = 'none'; // Safari
	document.body.style.MozUserSelect = 'none'; // Firefox
	document.body.style.msUserSelect = 'none'; // Internet Explorer/Edge
	document.body.style.userSelect = 'none'; // Non-prefixed version
	//在iPad上阻止默认的触摸滚动事件
	document.addEventListener('touchmove', preventDefault, {
		passive: false
	});

	// 阻止捏合缩放
	document.addEventListener('gesturestart', preventDefault, {
		passive: false
	});

	document.addEventListener('gesturechange', preventDefault, {
		passive: false
	});
	// 在某些设备上可能还需要监听 pinchstart 和 pinchend 事件
	document.addEventListener('pinchstart', preventDefault, {
		passive: false
	});
	document.addEventListener('pinchend', preventDefault, {
		passive: false
	});

	// document.addEventListener("keyup", handleKeyUp);
}


// function handleKeyUp(e) {
// 	console.log(e)
// 	if(e.key == "q"){
// 		screenDialog.init(server.local.getScreenSize())
// 	}
// }
Vue.prototype.$removeBanTouch = function(e) {
	document.removeEventListener('touchmove', preventDefault, {})
}

Vue.prototype.$addBanTouch = function(e) {
	document.addEventListener('touchmove', preventDefault, {
		passive: false
	});
}

preventAction();

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')