var isApp = false;
try {
	(async function() {
		if (CefSharp != undefined) {
			isApp = true;
			await CefSharp.BindObjectAsync("HtsCenter");
		}
	})();
} catch (ex) {
	console.log(ex);
}



function exitApp(code) {
	if (isApp) {
		HtsCenter.exit(code == null ? 0 : parseInt(code));
	}
}


module.exports = {
	isApp,
	exitApp
}