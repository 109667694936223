var box = {
	startX: 0,
	startY: 0,
	endX: 0,
	endY: 0,
	direction: '',

};

var lastTouchTime = 0;
var isDoubleTap = false;
var isSwipe = false;
var touchStartTime = null;
var touchEndTime = null;
var timeoutId = null;

var onSwipe = null;
var mycomp = null;
var touching = false;
var moving = false;

function init(comp, onswipe) {
	mycomp = comp;
	onSwipe = onswipe;

	if (mycomp == null) return;
	mycomp.addEventListener('touchstart', onTouchStart);
	mycomp.addEventListener('touchmove', onTouchMove);
	mycomp.addEventListener('touchend', onTouchEnd);

}

function close() {
	if (mycomp == null) return;
	mycomp.removeEventListener('touchstart', onTouchStart);
	mycomp.removeEventListener('touchmove', onTouchMove);
	mycomp.removeEventListener('touchend', onTouchEnd);

}

function onTouchStart(event) {
	// 清除之前的定时器
	if (timeoutId !== null) {
		clearTimeout(timeoutId);
	}

	// 保存当前触摸时间和位置
	touchStartTime = Date.now();
	box.startX = event.touches[0].clientX;
	box.startY = event.touches[0].clientY;
	moving = false;
	touching = true;
}

function onTouchMove(event) {
	if (!touching) return;
	box.endX = event.touches[0].clientX;
	box.endY = event.touches[0].clientY;
	moving = true;
}

function onTouchEnd(event) {
	if (!touching) return;

	// 检查滑动方向
	var diffX = moving ? box.endX - box.startX : 0;
	var diffY = moving ? box.endY - box.startY : 0;

	const now = Date.now();
	const timeDiff = now - lastTouchTime;
	const timeElapsed = now - touchStartTime;

	// 检查是否为双击
	if (timeDiff < 300 && Math.sqrt(diffX * diffX + diffY * diffY) < 20) {
		isDoubleTap = true;
		box.direction = 'Escape';
		swipeAction(box);
	} else if (timeElapsed < 500 && (Math.abs(diffX) > 50 || Math.abs(diffY) > 50)) {
		console.log("diff: " + diffX + " " + diffY);
		if (Math.abs(diffX) > Math.abs(diffY)) {
			// 主要是横向滑动
			if (diffX > 50) {
				box.direction = 'Right';
				swipeAction(box);
			} else if (diffX < -50) {
				box.direction = 'Left';
				swipeAction(box);
			}
		} else {
			// 主要是纵向滑动
			if (diffY > 50) {
				box.direction = 'Down';
				swipeAction(box);
			} else if (diffY < -50) {
				box.direction = 'Up';
				swipeAction(box);
			}
		}

	} else {
		// 单次触摸
		isDoubleTap = false;
	}

	// 设置一个定时器来重置lastTouchTime，防止长时间未发生触摸事件时误判双击
	timeoutId = setTimeout(() => {
		lastTouchTime = 0;
		isSwipe = false;
	}, 1000);

	// 更新上次触摸时间
	lastTouchTime = now;
	touching = false;
}

function swipeAction(box) {
	if (!onSwipe) return;
	onSwipe(box);
}


export default {
	init,
	close
}