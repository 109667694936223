const md5 = require('./md5.js')
const config = require('./enc.config.js');

function stringToByte(str) {
	var bytes = new Array();
	var len, c;
	len = str.length;
	for (var i = 0; i < len; i++) {
		c = str.charCodeAt(i);
		if (c >= 0x010000 && c <= 0x10FFFF) {
			bytes.push(((c >> 18) & 0x07) | 0xF0);
			bytes.push(((c >> 12) & 0x3F) | 0x80);
			bytes.push(((c >> 6) & 0x3F) | 0x80);
			bytes.push((c & 0x3F) | 0x80);
		} else if (c >= 0x000800 && c <= 0x00FFFF) {
			bytes.push(((c >> 12) & 0x0F) | 0xE0);
			bytes.push(((c >> 6) & 0x3F) | 0x80);
			bytes.push((c & 0x3F) | 0x80);
		} else if (c >= 0x000080 && c <= 0x0007FF) {
			bytes.push(((c >> 6) & 0x1F) | 0xC0);
			bytes.push((c & 0x3F) | 0x80);
		} else {
			bytes.push(c & 0xFF);
		}
	}
	return bytes;


}

function byteToString(arr) {
	if (typeof arr === 'string') {
		return arr;
	}
	var str = '',
		_arr = arr;
	for (var i = 0; i < _arr.length; i++) {
		var one = _arr[i].toString(2),
			v = one.match(/^1+?(?=0)/);
		if (v && one.length == 8) {
			var bytesLength = v[0].length;
			var store = _arr[i].toString(2).slice(7 - bytesLength);
			for (var st = 1; st < bytesLength; st++) {
				store += _arr[st + i].toString(2).slice(2);
			}
			str += String.fromCharCode(parseInt(store, 2));
			i += bytesLength - 1;
		} else {
			str += String.fromCharCode(_arr[i]);
		}
	}
	return str;
}

function base64encode(str) {
	return btoa(unescape(encodeURIComponent(str)));
}

function decodeBase64(str) {
	// atob 函数用于解码 Base64 字符串
	const binaryString = atob(unescape(decodeURIComponent(str)));

	// 创建一个 ArrayBuffer 来存储解码后的二进制数据
	const buffer = new ArrayBuffer(binaryString.length);
	const view = new Uint8Array(buffer);

	// 将解码后的字符逐个放入 ArrayBuffer 中
	for (let i = 0; i < binaryString.length; i++) {
		view[i] = binaryString.charCodeAt(i);
	}
	return buffer;
}

function getTimestamp() {
	return Math.round(Date.parse(new Date()) / 1000);
}

function getVerifyTag(url) {
	if (url == null || url.length == 0) return "";
	var bytes = stringToByte(url);
	var vf = stringToByte(config.privateKey);
	var len1 = bytes.length,
		len2 = vf.length,
		count = 0;
	for (var i = 0; i < len1; i++) {
		bytes[i] ^= vf[count++];
		if (count >= len2) count = 0;
	}

	var str = byteToString(bytes);
	return md5.encrypt(base64encode(str));
}

function getVerifyUrl(path) {
	if (path == null || path.length == 0) return "";
	var t = getTimestamp();
	var timetag = (path.indexOf('?') < 0) ? ("?t=" + t) : ("&t=" + t);
	var vtag = getVerifyTag(path + timetag);
	return path + timetag + "&v=" + vtag;
}

function getVerifyForm(params) {
	let str = base64encode(JSON.stringify(params));
	let time = getTimestamp();
	let vtag = getVerifyTag(time + str + time);
	return {
		data: str,
		time: time,
		vtag: vtag
	}
}


module.exports = {
	getVerifyUrl,
	getVerifyForm,

}