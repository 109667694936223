<template>
	<div id="app">
		<router-view />
		<ScreenDialog ref="screenDialog"></ScreenDialog>
		<div v-if="$isMobile && !isLandscape" class="orientation-prompt">
			<p>请将设备旋转至横屏以获得最佳体验。</p>
		</div>
	</div>
</template>

<script>
	import ScreenDialog from "@/views/my/dialog/ScreenDialog.vue";

	export default {
		components: {
			ScreenDialog,
		},
		data() {
			return {
				isFullscreen: false,
				isLandscape: false,

			};
		},
		watch: {
			isFullscreen: {
				handler(val) {
					if (!val) {
						if (this.$isMobile) {
							if (this.getLandscape()) {
								this.$message.warning("请在非游戏模式下双击屏幕，进入全屏显示");
							}
						} else {
							this.$message.warning("请按A键，进入全屏显示");
						}
					}
				},
				immediate: true,

			}
		},

		mounted() {
			this.setAppSize(true);
			if (this.$isMobile) {
				window.addEventListener('resize', this.reloadApp);
				window.addEventListener('orientationchange', this.reloadApp);
			}
			window.addEventListener("keyup", this.handlerKeyUp);
			document.addEventListener('fullscreenchange', this.updateFullscreenStatus);
			document.addEventListener('webkitfullscreenchange', this.updateFullscreenStatus);
			document.addEventListener('mozfullscreenchange', this.updateFullscreenStatus);
			document.addEventListener('MSFullscreenChange', this.updateFullscreenStatus);
		},
		methods: {

			getOrientation() {
				if (window.orientation) return window.orientation;
				if (window.screen.orientation && window.screen.orientation.angle)
					return window.screen.orientation.angle;
				return 0;
			},

			getLandscape() {
				const angle = this.getOrientation();
				this.isLandscape = angle == 90 || angle == -90;
				return this.isLandscape;
			},

			setAppSize(flag) {
				this.getLandscape();
				const app = document.getElementById('app');
				if (this.$isMobile) {
					app.style.width = `${window.innerWidth}px`;
					app.style.height = `${window.innerHeight}px`;
				} else {
					app.style.width = `${screen.width}px`;
					app.style.height = `${screen.height}px`;
				}

			},

			handlerKeyUp(e) {
				if (e.key == "q" || e.key == "Q") {
					this.$refs.screenDialog.init(this.$local.getScreenSize());
				}
				if (e.key == "a" || e.key == "A") {
					if (document.documentElement.requestFullscreen) {
						document.documentElement.requestFullscreen();
					} else if (document.documentElement.webkitRequestFullscreen) {
						// Safari 支持
						document.documentElement.webkitRequestFullscreen();
					} else if (document.documentElement.msRequestFullscreen) {
						// IE11 支持
						document.documentElement.msRequestFullscreen();
					}
				}
			},
			// 监听全屏状态
			updateFullscreenStatus() {
				const isFullscreen =
					document.fullscreenElement ||
					document.webkitFullscreenElement ||
					document.mozFullScreenElement ||
					document.msFullscreenElement;

				this.isFullscreen = !!isFullscreen;
			},

			reloadApp() {
				location.reload();
			}
		},
		beforeDestroy() {
			if (this.$isMobile) {
				window.removeEventListener('resize', this.reloadApp);
				window.removeEventListener('orientationchange', this.reloadApp);
			}

			window.removeEventListener("keyup", this.handlerKeyUp);
			document.removeEventListener('fullscreenchange', this.updateFullscreenStatus);
			document.removeEventListener('webkitfullscreenchange', this.updateFullscreenStatus);
			document.removeEventListener('mozfullscreenchange', this.updateFullscreenStatus);
			document.removeEventListener('MSFullscreenChange', this.updateFullscreenStatus);
		},
	};
</script>

<style lang="scss">
	// #app {
	// 	width: 100vw;
	// 	height: 100vh;
	// }

	.blackbk {
		position: relative;
		width: 100%;
		height: 100%;
		background-color: #000;
		overflow: hidden;
	}

	.orientation-prompt {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.75);
		font-size: 40px;
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1000;
		/* 确保遮罩层位于其他内容之上 */
	}
</style>