const userInfoTag = "__htsclient_userInfo__";
const deviceIdTag = "__htsclient_deviceId__";
const silenceTag = "__htsclient_sliencecode__";
const expireTag = "__htsclient_expireTime__";
const deviceTag = "__htsclient_deviceTag__";
const screenTag = "__htsclient_screenTag__";

//登录成功
function setUserInfo(userInfo, code) {
	if (userInfo == null) return;
	localStorage.setItem(userInfoTag, JSON.stringify(userInfo));
	let now = (new Date().getTime()) + 3 * 3600 * 1000;
	localStorage.setItem(expireTag, now);
	if (code == null) {
		localStorage.removeItem(silenceTag);
	} else {
		localStorage.setItem(silenceTag, code);
	}
}

function checkIsSlienceLogin() {
	return localStorage.getItem(silenceTag) != null;
}

//退出登录
function clearUserInfo() {
	localStorage.removeItem(userInfoTag);
	localStorage.removeItem(silenceTag);
}

function getUserInfo() {
	var user = localStorage.getItem(userInfoTag);
	if (user == null || user == "") return null;
	return JSON.parse(user);
}

//获取登录信息，userId>0登录成功，否则重新登录
function getUserId() {
	var user = getUserInfo();
	if (user == null || user.userId == null || user.userId == 0) return 0;
	var expireTime = localStorage.getItem(expireTag);
	var now = new Date().getTime();
	if (expireTime > now) return user.userId;
	clearUserInfo();
	return 0;
}

function getOrgId() {
	var user = getUserInfo();
	if (user == null) return null;
	return user.orgId;
}

function getUserName() {
	var user = getUserInfo();
	if (user == null) return null;
	return user.userName;
}

//存储设备Id
function setDeviceId(id) {
	localStorage.setItem(deviceIdTag, id);
}

function getDeviceId() {
	return localStorage.getItem(deviceIdTag);
}

function generateGUID() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		var r = Math.random() * 16 | 0,
			v = c == 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}

function getDeviceTag() {
	let tag = localStorage.getItem(deviceTag);
	if (tag != null) return tag;
	tag = generateGUID();
	localStorage.setItem(deviceTag, tag);
	return tag;
}


function getMainStyle(isMobile, myht) {
	let screenWidth = isMobile ? window.innerWidth : window.screen.width;
	let screenHeight = isMobile ? window.innerHeight : window.screen.height;
	let wrt = screenWidth / 1920;
	let rt = wrt;
	let rawht = 1080;
	if (myht) {
		rawht = (screenHeight - myht) * wrt;
	} else {
		let hrt = screenHeight / 1080;
		rt = Math.round(100 * Math.min(wrt, hrt)) / 100;
	}

	let mtp = (screenHeight - Math.round(1080 * rt)) / 2;
	let mlf = (screenWidth - Math.round(1920 * rt)) / 2;

	var style = "position: absolute;  width:1920px; height:1080px;";
	style += "left: " + mlf + "px; top: " + mtp + "px; overflow:hidden;";
	style += " transform:scale(" + rt + ");transform-origin: left top;";

	// console.log(style);
	return style;

}

function getAge(date) {
	try {
		if (date == null || date == "") return null;
		var birthDate = new Date(date);
		var currentDate = new Date();
		var age = currentDate.getFullYear() - birthDate.getFullYear();
		var monthDiff = currentDate.getMonth() - birthDate.getMonth();

		// 如果月份小于0或者月份相同但天数不够，则年龄减一
		if (monthDiff < 0 || (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())) {
			age--;
		}
		return age + "岁";
	} catch (err) {
		console.log(err);
	}
	return "";
}

function getScreenSize(withDefault) {
	let size = localStorage.getItem(screenTag);
	if (size == null || size == "") {
		if (withDefault) {
			return {
				width: 48.8,
				height: 36.58
			}
		}
		return null;
	}
	return JSON.parse(size);
}

function setScreenSize(size) {
	if (size == null) return;
	localStorage.setItem(screenTag, JSON.stringify(size));
}

module.exports = {
	getAge,
	setUserInfo,
	clearUserInfo,

	getUserId,
	getUserName,
	getOrgId,
	getUserInfo,

	setDeviceId,
	getDeviceId,
	getDeviceTag,
	checkIsSlienceLogin,
	getMainStyle,

	getScreenSize,
	setScreenSize

}