import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'splash',
		component: () => import('@/views/splashscreen/SplashScreen.vue'),
		meta: {
			title: '登录'
		}
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login/LoginView.vue'),
		meta: {
			title: '登录系统'
		}
	},
	{
		path: '/screen',
		name: 'screen',
		component: () => import('@/views/screen/screen.vue'),
		meta: {
			title: '输入屏幕尺寸'
		}
	},

	{
		path: '/vts',
		name: 'home',
		redirect: {
			name: 'treat'
		},
		component: () => import('@/views/home/HomeView.vue'),
		children: [{
				name: 'treat',
				path: 'treat',
				component: () => import('@/views/treatment/TreatView.vue'),
				meta: {
					title: '项目选择'
				}
			},
			{
				name: 'patient',
				path: 'performance',
				component: () => import('@/views/performance/performance.vue'),
				meta: {
					title: '项目表现'
				}
			},
			{
				name: 'overview',
				path: 'overview',
				component: () => import('@/views/overview/overview.vue'),
				meta: {
					title: '项目简介'
				}
			},
			{
				path: 'userinfo',
				name: 'userinfo',
				component: () => import('@/views/my/UserInfo.vue'),
				meta: {
					title: '我的信息'
				}
			}
		]
	},
	{
		path: '/params',
		name: 'params',
		component: () => import('@/views/params/paramsview.vue'),
		meta: {
			title: '参数设置'
		}
	},
	{
		path: '/result',
		name: 'result',
		component: () => import('@/views/result/result.vue'),
		meta: {
			title: '项目表现'
		}
	},
	{
		path: '/game',
		name: 'game',
		component: () => import('@/views/game/index.vue'),
		children: [{
				name: 'standard',
				path: 'standard',
				component: () => import('@/views/game/fusional/standard.vue'),
				meta: {
					title: '经典模式'
				}
			},
			{
				name: 'middlematch',
				path: 'middlematch',
				component: () => import('@/views/game/game10/middlematch.vue'),
				meta: {
					title: '周边视觉'
				}
			},
			{
				name: 'reversals',
				path: 'reversals',
				component: () => import('@/views/game/game11/reversals.vue'),
				meta: {
					title: '镜像字符识别'
				}

			},
			{
				name: 'visualMemory',
				path: 'visualMemory',
				component: () => import('@/views/game/game12/visualMemory.vue'),
				meta: {
					title: '视觉记忆'
				}

			}
		],
		meta: {
			title: '项目训练'
		}
	},
	{
		path: '/task',
		name: 'task',
		component: () => import('@/views/task/TaskView.vue'),
		meta: {
			title: '每日任务'
		}
	},
	{
		path: '/404',
		name: 'notfound',
		component: () => import('@/views/404/notFound.vue'),
		meta: {
			title: 'NotFound'
		}
	},
	{
		path: '/test',
		name: 'test',
		component: () => import('@/views/splashscreen/test.vue'),
		meta: {
			title: 'Test'
		}
	},
	{
		path: '/demo',
		name: 'demo',
		component: () => import('@/views/game/test.vue'),
		meta: {
			title: 'demo'
		}
	}

]


const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

// router.beforeEach((to, from, next) => {
//   // 如果路由不存在，则重定向到 404 页面
//   if (!router.options.routes.some(route => route.path === to.path)) {
//     next('/404') // 重定向到 404 页面
//   } else {
//     // 检查是否存在有效的路由
//     const validRoutes = router.options.routes.filter(route => route.path !== to.path);
//     if (validRoutes.length > 0) {
//       // 如果有其他有效路由，则继续导航
//       next();
//     } else {
//       // 如果没有其他有效路由，则重定向到 404 页面
//       next('/404');
//     }
//   }
// })


export default router
